var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fileUpload" }, [
    _vm.showPreview
      ? _c(
          "div",
          { staticClass: "imagePreview", staticStyle: { "margin-left": "7%" } },
          [
            _c(
              "a",
              {
                staticClass: "removeButton",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.removeImage()
                  },
                },
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["far", "circle-xmark"] },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "imageWrap" }, [
              _c("img", {
                attrs: { src: _vm.question.answer_file, alt: "Preview Image" },
              }),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "uploadField" }, [
      _c(
        "label",
        { attrs: { for: "id_" + _vm.question.id } },
        [
          _vm._v("\n      Upload Image\n      "),
          _c("font-awesome-icon", { attrs: { icon: ["far", "upload"] } }),
        ],
        1
      ),
      _vm._v(" "),
      _c("input", {
        ref: "fileInput",
        attrs: {
          type: "file",
          accept: "image/*",
          name: _vm.questionName,
          id: "id_" + _vm.question.id,
          capture: "environment",
        },
        on: {
          input: function ($event) {
            return _vm.imagePreview($event)
          },
          change: function ($event) {
            return _vm.updateQuestion(_vm.question)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "fileUpload" }, [
      _c("div", { staticClass: "fileUpload" }, [
        _c(
          "label",
          {
            staticStyle: { "margin-top": "12px" },
            on: { click: _vm.openCamera },
          },
          [
            _vm._v("\n        Capture Image\n        "),
            _c("i", { staticClass: "fa fa-camera" }),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.isCameraOpen
      ? _c("div", [
          _c("video", { ref: "video", attrs: { autoplay: "" } }),
          _vm._v(" "),
          _c("div", [
            _c(
              "i",
              {
                staticClass: "captureBtn fa fa-camera",
                on: { click: _vm.captureImage },
              },
              [_vm._v("Capture")]
            ),
            _vm._v(" "),
            _c(
              "i",
              {
                staticClass: "closeBtn fa fa-times-circle",
                on: { click: _vm.closeCamera },
              },
              [_vm._v("Close")]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }