import { render, staticRenderFns } from "./image_camera.vue?vue&type=template&id=249e0b38"
import script from "./image_camera.vue?vue&type=script&lang=js"
export * from "./image_camera.vue?vue&type=script&lang=js"
import style0 from "./image_camera.vue?vue&type=style&index=0&id=249e0b38&lang=less"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home2/staoush/public_maksym/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('249e0b38')) {
      api.createRecord('249e0b38', component.options)
    } else {
      api.reload('249e0b38', component.options)
    }
    module.hot.accept("./image_camera.vue?vue&type=template&id=249e0b38", function () {
      api.rerender('249e0b38', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "sites/default/js/components/assessment/fields/image_camera.vue"
export default component.exports