<template>

    <div class="basketActions">

        <template v-if="directToBasket">

            <form :action="url" method="post" v-if="product.in_stock" class="basketForm" ref="productForm">


                <div class="row" v-if="product.sizes.length">

                    <product-size-select
                            :sizes="product.sizes"
                            :collapse="collapseSize"
                            @change="updateSize"
                    ></product-size-select>

                </div> <!-- .row -->
                
                <template v-if="showDescription">
              
                    <div 
                            class="itemDescription wysiwyg" 
                            v-if="product.custom.description__product_card" 
                            v-html="product.custom?.description__product_card"
                    >
                    </div> <!-- .itemDescription -->

                </template>

                <div class="row basketButtonRow">

                    <div class="rowInput">

                        <label for="productQuantity">
                            Qty:
                        </label>

                        <mtc-number-input
                                :min="1"
                                :max="99"
                                :value="1"
                                :id="'productQuantity'"
                                :name="'quantity'"
                                @input="updateQty"
                        ></mtc-number-input>


                    </div> <!-- .rowInput -->

                    <div class="rowPrice">
                        <multi-currency-price :price="getDisplayPrice"/>
                      <div class="priceGrid">

                        <div class="priceGrid__left" v-if="subscriptionEnabled">

                          <div class="priceGrid__title priceGrid__title--highlight">
                            Subscribe to save!
                          </div> <!-- .priceGrid__title -->

                          <div class="priceGrid__select">

                            <input
                                type="radio"
                                name="purchase_type"
                                value="subscription"
                                v-model="purchase.type"
                                :id="'subscription' + formID"
                            />

                            <label
                                class="priceWrapper"
                                :for="'subscription' + formID"
                            >

                              <template v-if="subscriptionPrice">

                                <multi-currency-price :price="subscriptionPrice"/>

                                <multi-currency-price
                                    :price="subscriptionWasPrice"
                                    v-if="subscriptionWasPrice > 0"
                                    :strike="true"
                                />

                              </template>

                              <template v-else>

                                <multi-currency-price
                                    :price="priceSet"
                                    :product="product"
                                    :default-size-selected-id="defaultSizeSelectedId"
                                />

                                <multi-currency-price
                                    :price="wasPriceSet"
                                    v-if="wasPriceSet > 0"
                                    :strike="true"
                                    :product="product"
                                    :default-size-selected-id="defaultSizeSelectedId"
                                />

                                <multi-currency-price
                                    :price="pricePerTablet"
                                    v-if="pricePerTablet > 0"
                                    :product="product"
                                    :default-size-selected-id="defaultSizeSelectedId"
                                />

                              </template>


                            </label> <!-- .priceWrapper -->


                          </div> <!-- .priceGrid__select -->

                        </div> <!-- .priceGrid__left -->

                        <div class="priceGrid__right" v-if="priceSet && !subscriptionRequired">

                          <div class="priceGrid__title" v-if="subscriptionEnabled">
                            Or one time purchase
                          </div> <!-- .priceGrid__title -->

                          <div class="priceGrid__select">

                            <input
                                type="radio"
                                name="purchase_type"
                                value="buy"
                                v-model="purchase.type"
                                :id="'buy' + formID"
                            />

                            <label
                                class="priceWrapper"
                                :class="[subscriptionEnabled ? '' : 'hideRadio']"
                                :for="'buy' + formID"
                            >

                              <multi-currency-price :price="priceSet"/>

                              <multi-currency-price
                                  :price="wasPriceSet"
                                  v-if="wasPriceSet > 0"
                                  :strike="true"
                              />

                              <multi-currency-price
                                  :price="pricePerTablet"
                                  v-if="pricePerTablet > 0"

                              />

                            </label> <!-- .priceWrap -->

                          </div> <!-- .priceGrid__select -->

                        </div> <!-- .priceGrid__left -->

                      </div> <!-- .priceGrid -->
                    </div>

                    <div class="rowButton">

                        <input type="hidden" name="id" v-model="product.id">
                        <input type="hidden" name="add-bag" value="true">

                        <button
                                :type="ajaxAddToBasket ? 'button' : 'submit'"
                                class="button addToBagButton"
                                @click="addToBasketAjax($event)"
                        >
                            <span class="buttonLabel" v-html="label"></span>
                            <i class="svgIcon"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Vector" d="M10 5.48559L5.54444 9.99121L3.86667 9.99121L7.72222 6.09233L4.64936e-08 6.09233L6.08303e-08 4.89009L7.73333 4.89009L3.87778 0.991211L5.55556 0.991211L10 5.48559Z" fill="currentColor"></path></svg></i>
                        </button>

                    </div> <!-- .rowButton -->

                    <div class="ajaxLoader coverCard" :class="[loading ? 'isVisible' : '']" v-if="ajaxAddToBasket">

                        <loading-spinner
                                :loading="loading"
                                :show-loading-text="false"
                        ></loading-spinner>

                    </div> <!-- .ajaxLoading -->

                    <div class="ajaxMessage coverCard" :class="[msg.text.length ? 'isVisible' : '']" v-if="ajaxAddToBasket">

                        <div class="ajaxMessageWrap">
                            <p :class="[msg.class]" v-html="msg.text"></p>
                        </div> <!-- .ajaxMessageWrap -->

                    </div> <!-- .ajaxMessage -->

                </div> <!-- .row basketButtonRow -->

            </form>

        </template>

        <template v-else>

            <div class="basketForm" v-if="product.in_stock">
                
                
                <template v-if="showDescription">

                    <div
                            class="itemDescription wysiwyg"
                            v-if="product.custom.description__product_card"
                            v-html="product.custom.description__product_card"
                    >
                    </div> <!-- .itemDescription -->

                </template>
                
                
                <div class="row basketButtonRow">

                    <div class="rowPrice">
                        <multi-currency-price :price="getDisplayPrice"/>
                    </div>

                    <div class="rowButton">
                        <a
                            :href="url"
                            class="button addToBagButton"
                        >
                            <span class="buttonLabel" v-html="label"></span>
                            <i class="svgIcon"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Vector" d="M10 5.48559L5.54444 9.99121L3.86667 9.99121L7.72222 6.09233L4.64936e-08 6.09233L6.08303e-08 4.89009L7.73333 4.89009L3.87778 0.991211L5.55556 0.991211L10 5.48559Z" fill="currentColor"></path></svg></i>
                        </a>
                    </div>
                </div>
            </div>

        </template>

        <template v-if="!product.in_stock">

            <div class="basketForm">
                
                <template v-if="showDescription">

                    <div
                            class="itemDescription wysiwyg"
                            v-if="product.custom.description__product_card"
                            v-html="product.custom.description__product_card"
                    >
                    </div> <!-- .itemDescription -->

                </template>
                
                <div class="row basketButtonRow">

                    <div class="rowPrice">
                        <multi-currency-price :price="getDisplayPrice"/>
                    </div>

                    <div class="rowButton">
                        <a
                            class="button addToBagButton noStock"
                            :href="product.url"
                        >
                            <span class="buttonLabel">Out of Stock</span>
                        </a>
                    </div>
                </div>
            </div>

        </template>


    </div> <!-- .basketActions -->

</template>

<script>

import axios from 'axios';
import productSizeSelect from './product_size_select';
import MtcNumberInput from '../../../mtc_number_input'

export default {
    name: 'add-to-basket',
    components: {
        productSizeSelect,
        MtcNumberInput
    },
    props: {
        showDescription: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        ajaxAddToBasket: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        basketUrl: {
            type: [String],
            default (rawProps) {
                return '/shop/addtobasket.php';
            }
        },
        product: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        browsePage: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        basketButtonData: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        collapseSize: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        priceSet: {
          type: [Boolean, Number],
          default (rawProps) {
            return false;
          }
        },
        defaultSizeSelectedId: {
          type: [String, Number, Boolean],
          default (rawProps) {
            return false;
          }
        },
        wasPriceSet: {
          type: [Boolean, Number],
          default (rawProps) {
            return false;
          }
        },
    },
    data() {
        return {
            url: '',
            label: 'Add to basket',
            directToBasket: true,
            quantity: 1,
            selectedSize: 0,
            loading: false,
            msg: {
                class: 'errorMsg',
                text: ''
            },
            purchase: {
              type: 'buy'
            },
            formID: '',

        }
    },
    created() {

        this.url = this.basketUrl;

        if(this.type == 'browse') {
            this.label = this.basketButtonData.browse_label;
            this.url = this.basketButtonData.browse_url;
        }else{
            this.label = this.basketButtonData.label;
            this.url = this.basketButtonData.url;
        }

        this.directToBasket = this.basketButtonData.add_direct_to_basket;
    },
    computed: {
        getDisplayPrice() {
            let price,
                product = this.product,
                qty = this.quantity;
            if(this.selectedSize) {
                let size = product.sizes.filter(e => e.id === this.selectedSize)[0];
                if(size.sale_price < size.price && size.sale_price > 0) {
                    price = size.sale_price;
                    console.log('using sale size');
                } else {
                    price = size.price;
                }
            } else {
                if(product.sale_price < product.from_price && product.sale_price > 0) {
                    price = product.sale_price;
                    console.log('using sale price');
                } else {
                    price = product.from_price
                }
            }

            return price;
        },

      subscriptionPeriodValue() {

        let sizeSelected = this.product.sizes.filter(size => size.id == this.selectedSize);
        let totalDays = 1;


        if (sizeSelected.length) {

          sizeSelected = sizeSelected[0];

          if(typeof sizeSelected.subscription_preference != 'undefined' && sizeSelected.subscription_preference != null) {

            const interval = sizeSelected.subscription_preference.interval;
            let intervalMultiplier = sizeSelected.subscription_preference.interval_multiplier;

            //Build days
            const day = 1,
                weekDays = 7,
                monthDays = 31;

            switch (interval) {

              case 'months':
                totalDays = monthDays * intervalMultiplier;
                break;

              case 'weeks':
                totalDays = weekDays * intervalMultiplier;
                break;

              case 'days':
                totalDays = day * intervalMultiplier;
                break;

            }

          }

        }




        return totalDays + ' days';
      },

      subscriptionRequired() {

        let sizeSelected = this.product.sizes.filter(size => size.id == this.selectedSize);

        if(sizeSelected.length) {

          sizeSelected = sizeSelected[0];

          if(typeof sizeSelected.subscription_preference != 'undefined' && sizeSelected.subscription_preference != null) {

            if(sizeSelected.subscription_preference?.is_required) {

              return sizeSelected.subscription_preference.is_required;

            }

          }

        }

        return false

      },

      subscriptionEnabled() {

        if (this.product.sizes.length > 0) {

          let sizeSelected = this.product.sizes.filter(size => size.id == this.selectedSize);

          if (sizeSelected.length) {

            sizeSelected = sizeSelected[0];

            if (typeof sizeSelected.subscription_preference != 'undefined' && sizeSelected.subscription_preference != null) {

              if (sizeSelected.subscription_preference?.is_allowed) {

                return sizeSelected.subscription_preference.is_allowed;

              }

            }

          }
        }

        return false;

      },

      subscriptionPrice() {

        let sizeSelected = this.product.sizes.filter(size => size.id == this.selectedSize);

        if(sizeSelected.length) {

          sizeSelected = sizeSelected[0];

          if(typeof sizeSelected.subscription_preference != 'undefined' && sizeSelected.subscription_preference != null) {

            let price = sizeSelected.subscription_preference.price;
            let salePrice = sizeSelected.subscription_preference.sale_price;

            if (salePrice < price && salePrice > 0 && price != salePrice) {
              price = salePrice;
            }

            return price.toFixed(2);

          }

        }

        return 0;
      },

      subscriptionWasPrice() {

        let sizeSelected = this.product.sizes.filter(size => size.id == this.selectedSize);

        if(sizeSelected.length) {
          sizeSelected = sizeSelected[0];

          if(typeof sizeSelected.subscription_preference != 'undefined' && sizeSelected.subscription_preference != null) {
            let price = sizeSelected.subscription_preference.price;
            let salePrice = sizeSelected.subscription_preference.sale_price;

            if (salePrice > price || salePrice === 0 || price === salePrice) {
              price = 0;
            }

            return price.toFixed(2);

          }

        }

        return 0;
      },

      subscriptionPricePerTablet() {


        let sizeSelected = this.product.sizes.filter(size => size.id == this.selectedSize);

        if(sizeSelected.length) {
          sizeSelected = sizeSelected[0];

          if(typeof sizeSelected.subscription_preference != 'undefined' && sizeSelected.subscription_preference != null) {

            let price = sizeSelected.subscription_preference.price;

            return price.toFixed(2);

          }


        }

        return 0;

      },
      pricePerTablet() {

        try {
          const sizeSelected = this.product.sizes.filter(size => size.id == this.selectedSize);
          return parseFloat(sizeSelected[0].price);
        }catch(e) {

        }

        return 0;

      },
    },
    methods: {
        updateQty(qty) {
            this.quantity = Number(qty);
        },
        updateSize(id) {
            this.selectedSize = id
            this.setSubscription(id);
        },
        showMessage(text, success) {

            if(success) {
                this.msg.class = 'successMsg';
            }else{
                this.msg.class = 'errorMsg';
            }

            this.msg.text = text;


        },
        addToBasketAjax(event) {

            if(this.ajaxAddToBasket) {

                event.preventDefault();
                const vm = this;

                if(!vm.loading) {

                    vm.loading = true;

                    const form = this.$refs.productForm;
                    const form_data = new FormData(form);
                    form_data.set('ajax', 'true');

                    axios.post(this.url, form_data, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    })
                    .then((response) => {

                        const data = response.data;

                        vm.loading = false;

                        if (data.status == 'ok') {

                            EventBus.$emit('receiveBasketUpdate');
                            vm.showMessage('Added to basket', true);

                        }else{
                            vm.showMessage('Item could not be added to basket', false);
                        }

                    })
                    .finally(() => {
                        setTimeout(() => {
                            vm.showMessage('', false);
                        }, 4000);
                    });

                }


            }

        },
        setSubscription(sizeID) {

          let sizeSelected = this.product.sizes.filter(size => size.id == this.selectedSize);

          let subscriptionEnabled = false;

          if(sizeSelected.length) {

            sizeSelected = sizeSelected[0];

            if(typeof sizeSelected.subscription_preference != 'undefined' && sizeSelected.subscription_preference != null) {

              if(sizeSelected.subscription_preference?.is_allowed) {

                subscriptionEnabled = sizeSelected.subscription_preference.is_allowed;

              }

            }

          }


          if(subscriptionEnabled && 0) {
            this.purchase.type = 'subscription';
          }else{
            this.purchase.type = 'buy';
          }

        },
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .basketActions {

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .coverCard {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            background: rgba(255,255,255,0.8);
            backdrop-filter: blur(10px);
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            border-radius: 10px;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: opacity 200ms linear, visibility 200ms linear;

            &.isVisible {
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                transition: opacity 200ms linear, visibility 200ms linear;
            }

            &.ajaxMessage {

                z-index: 3;

                p {
                    margin: 0;
                    font-weight: bold;

                    &.successMsg {
                        color: @primary_color;
                    }

                    &.errorMsg {
                        color: @alert_color;
                    }
                }
            }



        }


        .row {
            margin: 0;
            float: none;
            clear: both;
        }

        .addToBagButton {

            margin: 0;
            width: 100%;

            &.noStock {
                opacity: 0.5;
                background: @dark_color !important;
                border-color: @dark_color !important;
                color: #fff !important;
            }
        }

        .basketButtonRow {
            float: none;
            clear: both;
            margin: 0;
            display: flex;
            flex-flow: row nowrap;
            gap: 10px;

            .rowInput {
                flex: 0 0 65px;
                display: flex;
                align-items: center;
                gap: 10px;

                label {
                    margin: 0;
                    .rems(10);
                    line-height: 12px;
                    color: @text_color;
                    font-weight: 400;
                }
            }

            .rowPrice {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                .rems(14);
                line-height: 18px;
                font-weight: 400;
            }

            .rowButton {
                flex: 1 1 100%;
                display: flex;
                align-items: center;
                padding-left: 16px!important;
            }
        }



    }




</style>
