<template>
  <div class="fileUpload">
    <!-- Image Preview -->
    <div class="imagePreview" v-if="showPreview" style="margin-left: 7%">
      <a href="#" class="removeButton" @click.prevent="removeImage()">
        <font-awesome-icon :icon="['far', 'circle-xmark']"/>
      </a>
      <div class="imageWrap">
        <img :src="question.answer_file" alt="Preview Image">
      </div>
    </div>

    <!-- Upload Field -->
    <div class="uploadField">
      <label :for="'id_' + question.id">
        Upload Image
        <font-awesome-icon :icon="['far', 'upload']"/>
      </label>
      <input type="file"
             accept="image/*"
             :name="questionName"
             :id="'id_' + question.id"
             ref="fileInput"
             capture="environment"
             @input="imagePreview($event)"
             @change="updateQuestion(question)"
      >
    </div>

    <!-- Capture from Camera Button -->
    <div class="fileUpload">
      <div class="fileUpload">
        <label @click="openCamera" style="margin-top: 12px">
          Capture Image
          <i class="fa fa-camera"></i>
        </label>
      </div>
    </div>
    <!-- Camera Modal -->
    <div v-if="isCameraOpen">
      <video ref="video" autoplay></video>
      <div>
      <i class="captureBtn fa fa-camera" @click="captureImage">Capture</i>
      <i class="closeBtn fa fa-times-circle" @click="closeCamera">Close</i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "imageCamera",
  props: {
    question: {
      type: Object,
      default: () => ({})
    },
    multiuserId: {
      type: [String, Number, Boolean],
      default: false
    }
  },
  data() {
    return {
      filesize: 0,
      isCameraOpen: false,
      stream: null
    };
  },
  computed: {
    questionName() {
      return this.multiuserId
          ? `questions[${this.multiuserId}][${this.question.original_id}]`
          : `questions[${this.question.id}]`;
    },
    showPreview() {
      return this.question.answer_file && this.question.answer_file!=='' && this.question.answer_file.length > 0 && !this.question.file_error;
    }
  },
  methods: {
    updateQuestion: _.debounce(function (question, inputEvent) {

      if (typeof inputEvent == 'undefined' || this.allowInputEvent) {
        question = this.validate(question);
        this.$emit('update-question', question);
      }

    }, 200),

    validate(question) {

      let canProceed = true;

      if (!question.answer_file.length && question.is_required) {
        canProceed = false;
      }

      if (question.answer_file.length && this.filesize > 10485760) {
        this.question.file_error = 'Image file is too large. To continue, please upload an image that\'s less that 10mb in size';
        canProceed = false;
      } else {
        this.question.file_error = '';
      }

      if (question.file_error.length) {
        canProceed = false;
      }

      question.can_proceed = canProceed;

      return question;

    },

    externallyValidate() {
      let question = this.question;
      question = this.validate(question);
      question.externalUpdate = true;
      this.$emit('update-question', question);
    },
    async openCamera() {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({video: true});
        this.isCameraOpen = true;
        this.$nextTick(() => {
          this.$refs.video.srcObject = this.stream;
        });
      } catch (err) {
        console.error("Error accessing camera: ", err);
      }
    },
    captureImage() {
      const video = this.$refs.video;
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");

      // Draw the video frame
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Get current date and time
      const now = new Date();
      const dateTimeString = now.toLocaleString(); // Format: "MM/DD/YYYY, HH:MM:SS"

      // Add date and time to the image
      ctx.font = "24px Arial";
      ctx.fillStyle = "white";  // Change to black if needed
      ctx.strokeStyle = "black"; // Add an outline for better visibility
      ctx.lineWidth = 2;

      // Position the date at bottom-left
      const x = 30;
      const y = canvas.height - 30;

      // Outline for better visibility
      ctx.strokeText(dateTimeString, x, y);
      ctx.fillText(dateTimeString, x, y);

      const imageUrl = canvas.toDataURL("image/png");
      console.log(imageUrl);
      this.$set(this.question, 'answer_file', imageUrl);
      this.$set(this.question, 'file_error', '');

      // Convert to File and set to input field
      this.dataURLtoFile(imageUrl, "captured_image.png").then((file) => {
        this.updateFileInput(file);
      });

      this.closeCamera();
    },
    async dataURLtoFile(dataUrl, filename) {
      const res = await fetch(dataUrl);
      const blob = await res.blob();
      return new File([blob], filename, {type: "image/png"});
    },
    updateFileInput(file) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);

      const inputElement = this.$refs.fileInput;
      if (inputElement) {
        inputElement.files = dataTransfer.files;
        inputElement.dispatchEvent(new Event("change", {bubbles: true}));
      }
    },
    closeCamera() {
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop());
      }
      this.isCameraOpen = false;
    },
    imagePreview(event) {
      const file = event.target.files[0];
      if (file) {
        this.filesize = file.size;
        if (file.size > 10485760) {
          this.question.file_error = "Image file is too large. Please upload an image under 10MB.";
        } else {
          this.question.answer_file = URL.createObjectURL(file);
          this.question.file_error = "";
        }
      }
    },
    removeImage() {

      if (this.question.answer_file) {
        URL.revokeObjectURL(this.question.answer_file);
      }

      this.$set(this.question, 'answer_file', '');
      this.$set(this.question, 'file_error', '');
      this.$set(this.question, 'answer', '');
      this.$emit('update-question', this.question);
      // Clear the file input field
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = '';
      }

    }
  }
};
</script>

<style lang="less">
.fileUpload {
  max-width: 300px;
  margin: auto;
}

.captureBtn, .closeBtn {

  font-size: 12px;
  cursor: pointer;
  margin: 5px;
}
.closeBtn {
  color:red;
}

video {
  width: 80%;
  max-width: 600px;
  border: 2px solid white;
  border-radius: 10px;
}
</style>
