<template>

    <div class="subscriptionActions">

        <div class="subscriptionActions__buttons subscriptionActions__buttonGroup">
            
            <template v-if="hasFailedPayment">
                
                <a
                    :href="payUrl"
                    class="button"
                    v-if="payUrl.length"
                >
                    Pay
                </a>
                
            </template>
            
            <template v-else-if="canBePaused">

                <template v-if="isActive">
                    <a
                        :href="pauseUrl"
                        class="button buttonAlt"
                        v-if="pauseUrl.length"
                    >
                        Pause
                    </a>
                    
                </template>

                <template v-else-if="isPaused">
                    
                    <a
                        :href="activateUrl"
                        class="button"
                        v-if="activateUrl.length"
                    >
                        Activate
                    </a>

                </template>
                
            </template>

            <template v-if="!isCancelled">

                <button
                    type="button"
                    v-if="!isCancelled && cancelUrl.length && canBeCancelled"
                    :disabled="cancelling"
                    @click.prevent="cancelling = true"
                    class="button buttonAlert"
                >
                    Cancel
                </button>

             
                <a
                    :href="editUrl"
                    class="button"
                    v-if="isEditable && editUrl.length"
                >
                    Edit
                </a>
              
                
            </template>
            
            <template v-else>

                <button
                        type="button"
                        :disabled="true"
                        class="button buttonAlert"
                >
                    Cancel
                </button>
                
            </template>
            
        </div> <!-- .subscriptionActions__buttons -->

        <div class="subscriptionActions__cancelReasons" v-show="cancelling">

            <div class="row">
                
                <label for="cancelReason">Please let us know why you would like to cancel this subscription:</label>
                
                <div class="selectWrap">

                    <chosen
                            :options="cancelReasons"
                            placeholder="Select a reason"
                            v-model="cancelReason"
                            class="no-chosen"
                            id="cancelReason"
                            name="cancelReason"
                    ></chosen>
                    
                </div> <!-- .selectWrap -->

                <div class="subscriptionActions__textBox" v-show="isOther">

                    <div class="row">
                        
                        <label 
                            for="otherReason"
                        >
                            Please provide more information:
                        </label>
                        
                        <textarea 
                                name="otherReason" 
                                id="otherReason"
                                v-model="otherReason"
                        ></textarea>

                    </div> <!-- .row -->
                    
                </div> <!-- .subscriptionActions__textbox -->

            </div> <!-- .row -->
            
        </div> <!-- .subscriptionActions__cancelReasons -->

        <div class="subscriptionActions__cancelConfirm subscriptionActions__buttonGroup" v-show="cancelling && ((cancelReason.length && cancelReason != 'Other') || otherReason.length)">
            
            <a 
                    :href="sendReasonURL" 
                    class="button buttonAlert mainCancel" 
                    :disabled="(!cancelReason.length && !isOther) || (isOther && !otherReason.length)"
            >
                Cancel Subscription
            </a>

            <button 
                    type="button" 
                    class="button buttonSuccess" 
                    @click.prevent="cancelling = false"
            >
                Continue Subscription
            </button>
            
        </div> <!-- .subscriptionActions__cancelConfirm -->

    </div> <!-- .subscriptionActions -->

</template>

<script>


export default {
    
    props: {

        hasFailedPayment: {
            type: [Boolean],
            default() {
                return false;
            }
        },

        payUrl: {
            type: [String],
            default() {
                return '';
            }
        },

        isPaused: {
            type: [Boolean],
            default() {
                return false;
            }
        },

        canBePaused: {
            type: [Boolean],
            default() {
                return false;
            }
        },

        pauseUrl: {
            type: [String],
            default() {
                return '';
            }
        },

        isActive: {
            type: [Boolean],
            default() {
                return false;
            }
        },

        activateUrl: {
            type: [String],
            default() {
                return '';
            }
        },

        isCancelled: {
            type: [Boolean],
            default() {
                return false;
            }
        },

        cancelUrl: {
            type: [String],
            default() {
                return '';
            }
        },

        isEditable: {
            type: [Boolean],
            default() {
                return false;
            }
        },

        editUrl: {
            type: [String],
            default() {
                return '';
            }
        },

        cancelReasons: {
            type: [Array, Object],
            default() {
                return [];
            }
        },

        nextRenewalDate: {
            type: [String, Date],
            default() {
                return '';
            }
        }

    },
    
    data() {
        
        return {
            
            cancelling: false,
            cancelReason: '',
            otherReason: ''
            
        }
        
    },
    
    computed: {
        
        isOther() {
            return this.cancelReason.toLowerCase() == 'other';
        },
        
        sendReasonURL() {
            
            const url = this.cancelUrl;
            let reason = '';
            
            if(this.cancelReason.toLowerCase() == 'other') {

                reason = this.otherReason;
                
            }else if(this.cancelReason.length) {

                reason = this.cancelReason;
                
            }
            
            if(reason.length) {
                
                return url + '?cancel_reason=' + reason;
            }
            
            return false;
            
        },

        canBeCancelled() {
            if(this.hasFailedPayment) return true;
            if (!this.nextRenewalDate) return true;
            const renewalDate = new Date(this.nextRenewalDate);
            const today = new Date();
            const daysUntilRenewal = Math.ceil((renewalDate - today) / (1000 * 60 * 60 * 24));
            return daysUntilRenewal > 14;
        }
    }
    
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    .subscriptionActions {
        
        .row {
            
            margin: 0 0 24px;

            label {
                float: none;
                clear: both;
                display: block;
                width: 100%;
            }

            textarea {
                float: none;
                clear: both;
                max-width: 450px;
            }
            
        }
        
        &__textBox {
            padding: 24px 0 0 0;
        }
        
        .selectWrap {
            max-width: 450px;
        }
        
        .button {
            
            .rems(16);
            line-height: 28px;
            margin: 0;
            padding: 4px 24px;
            
            &.buttonAlert {
                
                transition: color 200ms linear, background 200ms linear, border-color 200ms linear !important;

                &:hover, &:active, &:focus-visible {
                    transition: color 200ms linear, background 200ms linear, border-color 200ms linear !important;
                }
                
            }
        }
        
        &__buttonGroup {
            display: flex;
            gap: 12px;
            
            @media @mobile {
                
                flex-direction: column;
                
            }
            
            .button {
                
                @media @mobile {
                    
                    width: 100%;
                    
                }
                
            }
            
        }
        
        &__cancelReasons {
            
            padding-top: 16px;
            margin-top: 16px;
            border-top: 1px solid @border_color;
            
            .row {
                
                margin-bottom: 0 0 16px 0;
                
            }
        }
        
        &__cancelConfirm {
            padding-top: 16px;
        }

        .buttonAlert:not(.mainCancel) {
            margin-left: auto;
            background: fade(@alert_color, 0%);
            border-color: fade(@alert_color, 0%);
            color: fade(@text_color, 80%);
            text-align: right;
            
            @media @mobile {
                text-align: center;
            }

            &:hover, &:active, &:focus-visible {
                background: fade(@alert_color, 0%);
                border-color: fade(@alert_color, 0%);
                color: fade(@alert_color, 100%);
            }

            &[disabled="disabled"] {
                background: fade(@alert_color, 0%) !important;
                border-color: fade(@alert_color, 0%) !important;
                color: fade(@text_color, 40%) !important;
            }
        }
        
    }

</style>
